import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { CheckboxFieldsContainer } from './StockLocation.style';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';

import { Autocomplete, TextField } from '@mui/material';
import { ISignUpForm, StockLocationFormik as StockLocationFormikType } from './StockLocation.type';
import { AutocompleteElement, CheckboxElement, ChipsAutocompleteElement, InputElement } from '../FormElements';
import { TypeRegions } from '../../models/ApiRegions.type';

export const StockLocationFormik: React.FC<StockLocationFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        name: Yup.string().trim().required(Trans('messages.p.this_field_is_required')).min(3, 'Name is Too Short.'),
        address: Yup.string()
            .trim()
            .min(5, 'Address is Too Short.')
            .required(Trans('messages.p.this_field_is_required')),
        locationType: Yup.string().required(Trans('messages.p.this_field_is_required')),
        region: Yup.string().required(Trans('messages.p.this_field_is_required')),
        customerId: Yup.string()
            .when('locationType', {
                is: 2,
                then: Yup.string().required(Trans('messages.p.this_field_is_required')),
                otherwise: Yup.string().nullable()
            }),
        //show: Yup.boolean().required(Trans('messages.p.this_field_is_required')),
        reshipmentEnabled: Yup.boolean().required(Trans('messages.p.this_field_is_required')),
        email: Yup.string()
            .test('emailsValidation', Trans('messages.p.please_enter_valid_email'), function (value) {
                if (!value) {
                    return true;
                }

                const emailSchema = Yup.array().of(Yup.string().email());
                const emails = value.split(';').map((email) => email.trim());

                return emailSchema.isValidSync(emails);
            })
            .nullable()
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='space-around' direction='row'>
                                <InputElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    lg={4}
                                    md={4}
                                />

                                <AutocompleteElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.type')}
                                    name='locationType'
                                    value={values.locationType}
                                    error={errors.locationType}
                                    touched={touched.locationType}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    options={props.locationsType ? props.locationsType : []}
                                    defaultValue={props.locationsType.find(
                                        (locationType) => `${locationType.id}` === `${values.locationType}` ?? ''
                                    )}
                                    required={true}
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                    lg={4}
                                    md={4}
                                />

                                <Grid item lg={4} md={4} sm={6} xs={12} className='textFieldFormik'>
                                    <Autocomplete
                                        disabled={!props.canCreateAndUpdata}
                                        id='region'
                                        options={props.regions ? props.regions : []}
                                        onChange={(e, value: TypeRegions | null) =>
                                            setFieldValue('region', value ? value.id : '')
                                        }
                                        onBlur={handleBlur}
                                        getOptionLabel={(option) =>
                                            option.translationString
                                                ? option.translationString
                                                      .replace('_', ' ')
                                                      .replace(/\w\S*/g, (w) =>
                                                          w.replace(/^\w/, (c) => c.toUpperCase())
                                                      )
                                                : ''
                                        }
                                        defaultValue={props.regions.find(
                                            (region) => `${region.id}` === `${values.region}` ?? ''
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                value={values.region}
                                                name='region'
                                                {...params}
                                                size='small'
                                                label={
                                                    <span className='fieldRequired'>
                                                        <i>*</i> {Trans('messages.t.region')}
                                                    </span>
                                                }
                                                variant='outlined'
                                                helperText={errors.region ? errors.region : ''}
                                                error={errors.region ? true : false}
                                            />
                                        )}
                                    />
                                </Grid>

                                <AutocompleteElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.webTrack_customer')}
                                    name='customerId'
                                    value={values.customerId}
                                    error={errors.customerId}
                                    touched={touched.customerId}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    options={props.customers ? props.customers : []}
                                    defaultValue={props.customers.find(
                                        (customer) => `${customer.id}` === `${values.customerId}` ?? ''
                                    )}
                                    required={values.locationType === 2}
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />
 
                                {/*<CheckboxElement
                                    label={Trans('messages.t.use_as_shipping_address')}
                                    name='show'
                                    value={values.show}
                                    onChange={handleChange}
                                /> */}

                                <InputElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.address')}
                                    name='address'
                                    value={values.address}
                                    error={errors.address}
                                    touched={touched.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    multiline={true}
                                    lg={8}
                                    md={8}
                                    sm={12}
                                    required={true}
                                />

                                <CheckboxElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.reshipment')}
                                    name='reshipmentEnabled'
                                    value={values.reshipmentEnabled}
                                    onChange={handleChange}
                                    LabelStyle={CheckboxFieldsContainer}
                                    lg={4}
                                    md={4}
                                    sm={6}
                                />

                                <ChipsAutocompleteElement
                                    disabled={!props.canCreateAndUpdata}
                                    label={Trans('messages.t.shipment_acceptance_email')}
                                    name='email'
                                    value={values.email.split(';').filter((email) => email !== '')}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChange={(e, value: any) => {
                                        setFieldValue('email', value.join(';'));
                                    }}
                                    onBlur={handleBlur}
                                    required={false}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
